<template>
  <form
    id="payment-form"
    class="form vertical payment"
    v-on:submit.prevent="submit">
    <Spacer :y="2" />
    <CardIcons />
    <div id="card-info"></div>

    <ul class="btn-list horizontal" v-if="!hideBtn">
      <li>
        <button
          :class="[
            $style.btn, $style.primary,
            !flag.submit ? $style.disabled : ''
          ]"
          :disabled="!flag.submit"
          v-on:click="getConfirmCardsSetup">カード登録</button>
      </li>
    </ul>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import cf from '@/mixins/commonFunctions';
import CardIcons from '@/components/parts/CardIcons.vue';
import Spacer from '@/components/Spacer';

// stripe
const STRIPE_PUB_KEY = process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_STRIPE_PUBLIC_KEY_PROD
  : process.env.VUE_APP_STRIPE_PUBLIC_KEY_DEV;
const stripePromise = loadStripe(STRIPE_PUB_KEY);

export default {
  name: 'CardRegist',
  mixins: [cf],
  components: {
    CardIcons,
    Spacer,
  },
  props: {
    hideBtn: {
      type: Boolean,
      default: false,
    },
    plan: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      elements: null,
      clientSecret: null,
      flag: {
        submit: false,
      },
      message: {
        cardError: '',
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.generateCardElement();
  },
  methods: {
    async getCardSetupSecret() {
      let secret;
      await this.axios({
        method: 'GET',
        url: 'v1/stripe/customer/get/cardSetupSecret', // off_sessionで作成
      })
        .then((response) => {
          const res = response.data;
          secret = res.client_secret;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
        });
      return secret;
    },

    async getConfirmCardsSetup() {
      const stripe = await stripePromise;

      // リダイレクトが発生した時用に状態をLSに保存(/subscriptionの場合のみ)
      if (this.$route.path.includes('/subscription')) {
        // clientSecretと選択したプラン情報を保存
        cf.saveLocalStorage({ subscription: {
          clientSecret: this.clientSecret,
          plan: this.plan,
        } }, 'times');
      }
      const result = await stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.origin}${this.$route.path}`,
        },
        redirect: 'if_required',
      });

      // FAIL時はリダイレクトしない
      if (result.error) {
        console.error('Setup failed:', result.error);
        if (result.error) alert(result.error?.message || '支払い方法の認証中にエラーが発生しました。\n再度お試しいただくか、別の支払い方法を登録してください。');
        return;
      }

      this.showLoading();
      try {
        console.log('Setup succeeded:', result);
        const pmId = result.setupIntent.payment_method;
        let customerId = this.user.customer?.customer_id;
        // 顧客情報がない場合は作成
        if (!customerId) {
          const createCustomerResult = await this.createCustomer();
          customerId = createCustomerResult.data.customer.id;
          if (!customerId) {
            alert('お客様情報の登録中にエラーが発生しました。\n恐れ入りますが、最初からお試しください。');
            this.$router.push('/');
            return;
          }
        }

        // 作成したカードを顧客に紐づけ
        const attachResult = await this.attachPaymentMethod(customerId, pmId);
        if (attachResult.status !== 200 || attachResult.data.customer?.customer !== customerId) {
          alert('お支払いカードの紐づけに失敗しました。\n再度お試しください。');
          await this.$store.dispatch('user/update', null, { root: true });
          this.$router.push('/');
          return;
        }

        // subscriptionの場合はデフォルトカードを更新する
        if (this.$route.path.includes('/subscription')) {
          // リダイレクトしなかった場合なので、LSのデータは削除
          cf.deleteLocalStorage('times', 'subscription');
          await this.updateDefaultCard(customerId, result.setupIntent.payment_method, 'paymentMethod');
          this.$emit('updatedDefaultCard');
        }
        const customerData = { customer: { customer_id: customerId } };
        await this.$store.dispatch('user/subscribe/getCustomer', customerData, { root: true });
        await this.$store.dispatch('user/update', null, { root: true });
        this.$emit('registed');
      } catch (error) {
        console.log(error);
        alert('情報の登録中にエラーが発生しました。\n恐れ入りますが、最初からお試しください。');
        await this.$store.dispatch('user/update', null, { root: true });
        this.$router.push('/');
      } finally {
        this.hideLoading();
      }

    },

    async createCustomer() {
      const customerData = {
        email: this.user.email,
        name: !this.user.last_name && !this.user.first_name ? '名前未設定' : `${this.user.last_name} ${this.user.first_name}`,
        metadata: { user_id: this.user.id },
        preferred_locales: ['ja'],
      };
      try {
        const result = await this.axios({
          method: 'POST',
          url: '/v1/stripe/customer/set/register',
          data: {
            customerData,
          },
        });
        return result;
      } finally {
        this.hideLoading();
      }
    },

    async attachPaymentMethod(customerId, pmId) {
      try {
        const result = await this.axios({
          method: 'POST',
          url: '/v1/stripe/customer/attach/paymentMethod',
          data: {
            customer_id: customerId,
            payment_method_id: pmId,
          },
        });
        return result;
      } finally {
        this.hideLoading();
      }
    },

    async updateDefaultCard(customerId, cardId, type) {
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/stripe/update/defaultCard',
          data: { type, customerId, cardId },
        });
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          alert('お支払いカードの設定に失敗しました。再度お試しください。');
        } else {
          console.log(error);
        }
        throw error;
      } finally {
        this.hideLoading();
      }
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** カード登録の要素を作成 */
    async generateCardElement() {
      const stripe = await stripePromise;
      const clientSecret = await this.getCardSetupSecret();
      this.elements = stripe.elements({
        clientSecret,
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro' }],
      });
      this.clientSecret = clientSecret;

      const paymentElement = this.elements.create('payment', { layout: 'accordion' });
      paymentElement.mount('#card-info');

      paymentElement.on('change', (event) => {
        if (event.complete) this.flag.submit = true;
        else this.flag.submit = false;
        this.$emit('sendFlag', this.flag.submit);
      });
    },

    /** 登録フラグの受け取り */
    receiveRegistFlag(data) {
      this.flag.completed.card = data.completed;
      this.flag.submit = this.flag.completed.card;
    },

    registed() {
      this.$emit('registed');
    },
  },
};
</script>

<style lang="scss" module>
.form {
  margin-top: 24px;
  dl {
    margin-bottom: 24px;
    margin-top: 0;
  }
  label {
    font-size: 12px;
  }
  .input {
    border: 1px solid var(--lightgray);
    border-radius: 4px;
    padding: 4px 8px;
  }
  dd {
    margin-left: 0;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  min-width: 128px;
  height: 40px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  transition: all .3s;

  &.primary {
    background-color: rgba(26, 34, 61, 1);
    border: none;
    color: #fff;
    &:hover, &.disabled {
      opacity: .6;
      cursor: default;
    }
  }

  &.secondary {
    border: 1px solid rgba(26, 34, 61, 1);
    color: var(--font-black);
    background-color: #fff;
    &:hover {
      opacity: .4;
    }
  }
}
</style>
