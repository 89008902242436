<template>
  <ColumnContainer :class="[flag.notFound ? $style.column : '']">
    <Loading v-if="flag.isLoading" />
    <MainColumn v-if="!flag.isLoading && !flag.notFound">
      <div :class="$style.container" v-if="article" id="scroll-top">
        <header :class="$style.header">
          <h1 :class="$style.title">{{ article.title }}</h1>
          <Spacer :y="2.5" />
          <div>
            <div :class="$style.meta">
              <!-- <span :class="$style.isNew">NEW</span> -->
              <div v-if="article.categories[0]" :class="$style.category">
                {{ article.categories[0].label }}
                <span v-if="article.categories[0]" :class="$style.slash">/</span>
              </div>
              <time :class="$style.createdAt">{{ formatTimestamp(article.releaseDate, 'YYYY.MM.DD HH:mm') }}</time>
            </div>
            <Spacer :y="2" v-if="article.tags.length" />
            <ArticleTag v-if="article.tags.length">
              <ArticleTagItem
                v-for="item in article.tags"
                :key="item.id"
                :tag="item"
              />
            </ArticleTag>
          </div>
          <Spacer :y="article.tags.length ? 1.5 : 2.5" />
          <ArticleThumbnail v-if="article.urls.thumbnail.length > 0"
            :class="$style.thumb"
            :src="article.urls?.thumbnail?.[0]?.image?.url"
            :alt="article.title"
          />
          <Spacer :y="0.5" v-if="article.urls?.thumbnail?.[0]?.image?.comment"/>
          <p
            :class="$style.thumb_comment"
            v-if="article.urls?.thumbnail?.[0]?.image?.comment">{{ article.urls.thumbnail[0].image.comment }}</p>
          <Spacer :y="2.5" />
          <p
            :class="$style.description"
            v-html="allowReadPaidContent ? article.description : omitDescription(article.description)"></p>
        </header>
        <Spacer :y="10" />
        <div
          v-if="(!flag.isLimited || (flag.isLimited && allowReadPaidContent)) && article.content"
          :class="$style.content" style="white-space: pre-line;"
          v-html="addProtectClassContent(article.content)" />
        <div
          v-if="(!flag.isLimited || (flag.isLimited && allowReadPaidContent)) && galleryImages.length"
          :class="$style.gallery">
          <Spacer :y="6"/>
          ギャラリー
          <ImageScroller
            :images="galleryImages"
            v-on:selectIndex="changeImg" />
          <viewer
            v-if="viewerIdx !== null"
            :images="galleryImages"
            :showIdx="viewerIdx"
            :showPage="false"
            :listReverse="false"
            v-on:changePage="changeImg" />
        </div>
        <div v-if="flag.isLimited && (!allowReadPaidContent || [0,1].includes(checkUserPlanStatus))"
          :class="$style.limitMessage">
          {{ [0].includes(checkUserPlanStatus) ? '本文を読むには登録が必要です' : '' }}
          {{ [2].includes(checkUserPlanStatus) && !isWebPlan ? '本文を読むには電子版プランまた電子版+紙面配達プランのお申し込みが必要です' : '' }}
        </div>
        <div
          v-if="flag.isLimited
            && !allowReadPaidContent
            && [0, 1, 2].includes(checkUserPlanStatus)"
          :class="$style.wrap">
          <router-link
            v-if="[0].includes(checkUserPlanStatus)"
            :to="'/login/'"
            :class="[$style.login, $style.btn]"
            @click="saveLoginRef">
            ログイン
          </router-link>
          <router-link
            :to="[0].includes(checkUserPlanStatus)
              ? '/subscriptions/'
              : '/edit/subscription/'"
            :class="[$style.signin, $style.btn]">
            {{ [0].includes(checkUserPlanStatus) ? '新規会員登録' : '' }}
            {{ [1].includes(checkUserPlanStatus) ? '定期購読お申し込み' : '' }}
            {{ [2].includes(checkUserPlanStatus) && !isWebPlan ? 'プラン変更' : '' }}
          </router-link>
        </div>
        <spacer :y="10"/>

        <Adsense
          v-if="helper.env.name === 'production'"
          :class="$style.adsense"
          data-ad-client="ca-pub-4866689451938023"
          data-ad-slot="6377342484"
          data-ad-format="rectangle"
          data-full-width-responsive="true" />

        <Spacer :y="10"
          v-if="helper.env.name === 'production'" />
        <div :class="$style.paperlink_wrap"
          v-if="!article.onlyWeb && article2Paper && article2Paper.date">
          <div :class="$style.paperlink">
            <router-link :to="paperLink">
              <div :class="$style.img">
                <div
                  :class="[$style.img_bg, !allowReadPaidContent ? $style.blur : '']"
                  :style="`background-image: url(${article2Paper && article2Paper.date && article2Paper.page ? article2Paper.url : `/img/paperlink/default.jpg`})`" />
                <div
                  :class="$style.frame"
                  style="background-image: url(/img/paperlink/frame.png)"/>
              </div>
            </router-link>
            <div :class="$style.paperlink_right">
              <p :class="$style.paperlink_text"><span v-show="this.article2Paper.page">この記事は</span>紙面版でも<br>ご覧いただけます。</p>
              <router-link
                :to="paperLink"
                :class="[$style.btn, $style.signin, $style.onlypc]">紙面版で読む</router-link>
            </div>
          </div>
          <router-link
            :to="paperLink"
            :class="[$style.btn, $style.signin, $style.onlysp]">紙面版で読む</router-link>
        </div>
      </div>
    </MainColumn>
    <NotFound v-if="!flag.isLoading && flag.notFound" :message="notFoundMessage" />
    <SideColumn v-if="!flag.isLoading" :notFound="flag.notFound" :article="article" />
  </ColumnContainer>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Spacer from '@/components/Spacer';
import {
  ColumnContainer,
  MainColumn,
  SideColumn,
} from '@/components/layouts';
import {
  ArticleTag,
  ArticleTagItem,
} from '@/components/ArticleTag';
import cf from '@/mixins/commonFunctions.js';
import Loading from '@/components/Loading.vue';
import ArticleThumbnail from '@/components/ArticleList/ArticleThumbnail';
import NotFound from '@/views/Error/NotFound.vue';
import ImageScroller from '@/components/ImageScroller';
import Viewer from '@/components/Viewer/Viewer.vue';

export default {
  name: 'ArticleSinglePage',
  mixins: [cf],
  components: {
    ColumnContainer,
    MainColumn,
    SideColumn,
    Spacer,
    Loading,
    ArticleThumbnail,
    NotFound,
    ImageScroller,
    Viewer,
    ArticleTag,
    ArticleTagItem,
  },
  data() {
    return {
      slug: null,
      article: null,
      article2Paper: null,
      flag: {
        isLoading: true,
        isLimited: false,
        isGooglebot: false,
        notFound: false,
      },
      notFoundMessage: 'お探しの記事は見つかりませんでした',
      viewerIdx: null,
      webPlanPriceIds: [],
    };
  },
  created() {
    this.flag.isLoading = true;
    this.slug = this.$route.params.slug;
    if (window.navigator.userAgent.toLowerCase().includes('googlebot')) this.flag.isGooglebot = true;
    if (this.helper?.prices?.web) {
      this.getWebPlanPriceIds();
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putPrices') {
          this.getWebPlanPriceIds();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    isPaid() {
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && moment(new Date()).isBefore(moment(this.user.customer.subscriptions[0].expire)));
    },
    oldPriceIds() {
      if (this.helper && this.helper.master) return this.helper.master.products.old_price_type.map((row) => row.id);
      return [];
    },
    isWebPlan() {
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && this.webPlanPriceIds.length
        && (this.webPlanPriceIds.includes(this.user.customer.subscriptions[0].price_id)
          || this.oldPriceIds.includes(this.user.customer.subscriptions[0].price_id)
        ));
    },
    isTrial() {
      return Boolean(this.user.email
        && this.user.limit_trial
        && moment(new Date()).isBefore(moment(this.user.limit_trial), 'day'));
    },
    isValidDevice() {
      return Boolean(this.user && this.user.login && this.user.login.isValidDevice);
    },
    allowReadPaidContent() {
      // googlebotには全コンテンツを見せる
      if (this.flag.isGooglebot) return true;
      // 特例対応(会員登録だけで有料コンテンツを確認できるかどうか)
      const specialFlag = 0;
      if (!specialFlag) { // デフォルトの判定
        if (this.user.role && [3, 4, 5, 6, 7, 8].includes(this.user.role.role)) return true; // v2・開発・ゲスト のいずれかの権限の場合には閲覧可能
        return ((this.isPaid && this.isWebPlan) || this.isTrial) && this.isValidDevice;
      }
      return Boolean(this.user.email);
    },
    checkUserPlanStatus() {
      // ユーザの会員ステータスを確認（未登録者、無料会員、有料会員、開発｛例外｝）のどれに当てはまるか）
      let status = 0; // デフォルト（未登録：０）

      if (this.user.role && [3, 4, 5, 6, 7, 8].includes(this.user.role.role)) status = 3; //　（例外）v2・開発・ゲスト のいずれかの権限：３
      else if (this.isValidDevice && (this.isPaid || this.isTrial)) status = 2; //　有料会員：２
      else if (this.user.email) status = 1; //　無料会員：１
      return status;
    },
    paperLink() {
      if (!this.article2Paper || (!this.article2Paper.date && !this.article2Paper.page)) {
        return '/paper/latest/';
      }
      if (!this.article2Paper.page) {
        return `/paper/${this.article2Paper.date}/`;
      }
      return `/paper/${this.article2Paper.date}/?page=${this.article2Paper.page}`;
    },
    galleryImages() {
      const imgs = [];
      if (this.article && this.article.galleries.length && this.article.galleries[0].urls.length) {
        this.article.galleries[0].urls.forEach((row) => {
          imgs.push({
            url: row.image.url,
            caption: row.image.comment,
          });
        });
      }
      return imgs;
    },
  },
  watch: {
    $route(to) {
      this.slug = to.params.slug;
      if (to.path.includes('article') && this.slug) this.getArticle();
    },
  },
  methods: {
    getWebPlanPriceIds() {
      this.flag.isLoading = true;
      if (!this.helper?.prices?.web) {
        this.webPlanPriceIds = [];
        return;
      }
      const webPlanId = this.helper.prices.web.id;
      const webPaperPlanId = this.helper.prices.webPaper.id;
      this.webPlanPriceIds = [webPlanId, webPaperPlanId];
      this.getArticle();
    },
    addProtectClassContent(content) {
      return content.replace('<p><img', `<p oncontextmenu=”return false;”><span class="${this.$style.protectImg}"></span><img`);
    },
    getArticle() {
      this.flag.isLoading = true;
      const params = {
        slug: this.slug,
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/detail',
        params,
      })
        .then(async (response) => {
          const res = response.data;
          if (!res.detail) {
            this.flag.notFound = true;
            return;
          }
          this.article = res.detail;
          this.flag.isLimited = res.detail.limited;
          if (this.$route.path.includes('article')) this.setMetas();

          await this.checkPaperStatus();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading = false;
        });
    },

    changeImg(idx) {
      this.viewerIdx = idx;
    },

    async checkPaperStatus() {
      // releaseDateとタイトルで紙面の紐付けがあるかどうかbackendで確認する
      const params = {
        releaseDate: this.article.releaseDate || null,
        keywords: this.article.title ? this.adjustTitleStr(this.article.title) : null,
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/check/paper',
        params,
      })
        .then(async (response) => {
          const res = response.data;
          this.article2Paper = res;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading = false;
        });
    },
    // descriptionの文末を…にする
    omitDescription(des) {
      if (des) {
        let omittedStr = des;
        if (des.length > 80) {
          omittedStr = des.substring(0, 80);
          omittedStr += '...';
        } else {
          omittedStr = omittedStr.slice(0, -3);
          omittedStr += '...';
        }
        return omittedStr;
      }
      return '';
    },

    adjustTitleStr(str) {
      // 半角文字にする
      const halfStr = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
      const kanjiKeywords = halfStr.match(/[\u4E00-\u9FFF\u3005-\u3007]+/g);
      const keywords = {};
      kanjiKeywords.forEach((word, i) => {
        keywords[`keyword_${i + 1}`] = [word];
      });
      return keywords;
    },

    setMetas() {
      const defaults = {
        title: '桐生タイムス',
        description: '群馬県東部の桐生・みどり両市を拠点とする新聞社。日刊の夕刊紙「桐生タイムス」のほか週刊のフリーペーパー「タウンわたらせ」「週刊渡良瀬通信」を発行しています。',
        og_image: 'https://kiryutimes.s3.ap-northeast-1.amazonaws.com/production/OGP/ogp.png',
      };

      const item = cf.methods.getClone(this.article);
      const meta = {};

      meta.title = `${item.title} | ${defaults.title}`;
      meta.description = item.description || defaults.description;
      meta.og_image = item.urls?.thumbnail?.[0]?.image?.url || defaults.og_image;
      const split = location.pathname.split('/');
      const trailingSlash = split[split.length - 1] !== '' ? '/' : '';
      meta.og_url = `${location.protocol}//${location.host}${location.pathname}${trailingSlash}`;
      meta.type = 'article';
      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:type']").setAttribute('content', meta.type);
      document.querySelector("meta[property='og:image']").setAttribute('content', meta.og_image);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // canonicalタグの動的生成
      const canonical = document.querySelector("link[rel='canonical']");
      if (canonical) {
        // 存在する場合は書き換え
        canonical.href = meta.og_url;
      } else {
        // 存在しない場合は生成
        const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'canonical');
        linkTag.href = meta.og_url;
        document.head.appendChild(linkTag);
      }
    },

    openSignupModal() {
      const args = {
        modalName: 'UserLimited',
        data: null,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    saveLoginRef() {
      const loginRef = { path: this.$route.fullPath };
      cf.saveLocalStorage({ loginRef: JSON.stringify(loginRef) }, 'times');
    },
  },
};
</script>

<style lang="scss" module>
.container {
  max-width: 720px;
  width: 100%;
  margin: auto;
}

.header {
  padding-bottom: calc(var(--s) * 2.5);
  border-bottom: 1px solid var(--border-gray);
}

.title {
  font-size: 32px;
  line-height: 1.4;
  font-weight: 400;
}

.meta {
  display: flex;
  align-items: center;
}

.isNew {
  display: block;
  width: fit-content;
  padding: 4px 11px;
  border-radius: 2px;
  background-color: var(--accent);
  color: #fff;
  font-weight: 900;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.04em;
}

.createdAt {
  font-size: 12px;
  line-height: 1;
  color: var(--font-gray);
}

.category {
  padding-right: 5px;
  font-size: 12px;
  line-height: 1;
  color: var(--font-gray);
}

.slash {
  padding-right: 5px;
  font-size: 12px;
  line-height: 1;
  color: var(--font-gray);
}

.content {
  color: var(--font-gray);
  p {
    position: relative;
  }
  img {
    display: inline;
  }

  ul {
    padding-left: 1em;
    li {
      list-style-type: disc;
    }
  }
  ol {
    padding-left: 1em;
    li {
      list-style-type: decimal;
    }
  }
}

.thumb {
  margin: 0 auto;
  &_comment {
    color: var(--font-gray);
    font-weight: bold;
  }
}

.wrap {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.btn {
  margin: 0 0 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 320px;
  height: 48px;
  padding: 13px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  border-radius: 44px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
}

.signin {
  background-color: rgba(26, 34, 61, 1);
  border: none;
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.login {
  border: 1px solid rgba(26, 34, 61, 1);
  color: var(--font-black);
  background-color: #fff;
}

.limitMessage {
  margin: 0 0 12px 0;
  color: rgba(26, 34, 61, 0.72);
  font-size: 14px;
  text-align: center;
}

@include sm-view {
  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .thumb {
    margin: 0 calc(50% - 50vw);
    max-width: 100vw;
    width: 100vw;
  }

  .adsense {
    text-align: center;
  }
}

.column {
  flex-direction: column;
  justify-content: flex-start;
}

.paperlink {
  &_wrap {
    padding: 30px;
    border: 1px solid rgba(24, 24, 24, .32);
    border-radius: 8px;
  }
  display: flex;

  .img {
    $w: 178px;
    display: block;
    position: relative;
    &_bg {
      width: 90%;
      height: 84%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-size: cover;
      &.blur {
        filter: blur(1px);
      }
    }
    .frame {
      width: $w;
      padding-top: calc($w * 719 / 500);
      background-size: cover;
      @include sm-view {
        width: 120px;
        padding-top: calc(120px * 719 / 500);
      }
    }
  }

  &_right {
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    .btn {
      min-width: 200px;
    }
  }

  &_text {
    text-align: center;
    font-size: 21px;
    margin-bottom: 40px;
    color: rgba(#000000, .72);
    @include sm-view {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }
}

.onlypc {
  @include sm-view {
    display: none;
  }
}

.onlysp {
  display: none;
  @include sm-view {
    min-width: auto;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0px;
    display: flex;
  }
}

.protectImg {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
