<template>
  <div :class="$style.container">
    <div
      :class="[$style.login, $style.btn]"
      @click="changeUserStatus"
    >
      {{ user.login.isLogin ? 'ログアウト' : 'ログイン' }}
    </div>
    <Spacer :x="1.5"/>
    <router-link
      :to="'/subscriptions/'"
      v-if="!user.login.isLogin"
      :class="[$style.signin, $style.btn]"
      @click="saveLoginRef"
    >
      新規会員登録
    </router-link>
    <router-link
      v-if="user.login.isLogin"
      to="/account/">
      <div
        :class="$style.icon"
        :style="`background-image: url(${getMyIconUrl(user)})`"/>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Spacer from '@/components/Spacer';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'AccountLinks',
  mixins: [cf],
  components: {
    Spacer,
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    if (this.user.email) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.initialize();
        }
      });
    }
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
    ]),
    initialize() {
      this.$store.dispatch('user/login/check');
    },

    logout() {
      this.$store.dispatch('user/login/logout', null, { root: true });
      this.$router.push({ path: '/' });
    },

    changeUserStatus() {
      if (this.user.login.isLogin) {
        this.logout();
      } else {
        this.saveLoginRef();
        this.$router.push({ path: '/login/' });
      }
    },

    saveLoginRef() {
      const current = this.$router.options.history;
      const ref = { path: current.location };
      // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
      cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, 'times');
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 128px;
  height: 40px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
}

.signin {
  background-color: rgba(26, 34, 61, 1);
  border: none;
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.login {
  border: 1px solid rgba(26, 34, 61, 1);
  color: var(--font-black);
  background-color: #fff;
}

.icon {
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
</style>
