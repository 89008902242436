
import axios from '@/plugins/axios';
// import cf from '@/mixins/commonFunctions';

const actions = {
  getCustomer({ commit, dispatch }, data) {
    axios({
      method: 'GET',
      url: '/v1/stripe/customer/get/stripeData',
      params: {
        customer_id: data.customer.customer_id,
      },
    })
      .then((response) => {
        const res = response.data;
        dispatch('getCardData', data);
        commit('putDefaultSource', res.customer);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  /** IDを元にカード詳細を取得 */
  getCardData({ commit }, data) {
    axios({
      method: 'GET',
      url: '/v1/stripe/customer/get/cards',
      params: {
        customerId: data.customer.customer_id,
      },
    })
      .then((response) => {
        const res = response.data;
        /** paymentMethodsには既存登録のカードが含まれる */
        if (res.data.paymentMethods.length) {
          const shapeCards = res.data.paymentMethods.map((item) => ({
            id: item.id,
            ...item.card,
            type: item.id.substring(0, 2) === 'pm' ? 'paymentMethod' : 'card',
          }));
          commit('putAllData', {
            name: 'cards',
            data: shapeCards,
          });
        }
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  setTemporaryCard({ commit }, data) {
    commit('setTemporaryCard', data);
  },

  resetTemporaryCard({ commit }) {
    commit('resetTemporaryCard');
  },

  reset({ commit }) {
    commit('reset');
  },
};


const mutations = {
  /** obj.dataがobject */
  putObjectData(state, obj) {
    const keys = Object.keys(obj.data);
    if (!keys.length) return;

    const items = Object.keys(state[obj.name]);
    // state.stripeに含まれる項目のみを追加
    keys.forEach((key) => {
      if (items.includes(key)) {
        state[obj.name][key] = obj.data[key];
      }
    });
  },

  /** obj.dataのすべてを格納 */
  putAllData(state, obj) {
    if (obj.name === 'cards') {
      state.cards[obj.name] = obj.data;
      state.cards.flag = true;
    } else {
      state[obj.name] = obj.data;
    }
  },

  putDefaultSource(state, customer) {
    const oldTypeDefaultId = customer.default_source;
    const paymentMethodDefaultId = customer.invoice_settings.default_payment_method;
    state.cards.oldDefault = oldTypeDefaultId;
    state.cards.default = paymentMethodDefaultId;
  },

  setTemporaryCard(state, data) {
    state.cards.temporary = data;
  },

  resetTemporaryCard(state) {
    state.cards.temporary = {};
  },

  reset(state) {
    state.cards.flag = false;
    state.cards.default = null;
    state.cards.cards = [];
    state.cards.temporary = {};
  },
};


const state = {
  cards: {
    flag: false,
    default: null,
    oldDefault: null,
    cards: [],
    temporary: {},
  },
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
