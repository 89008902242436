<template>
  <div>
    <!-- デフォルトカード変更 -->
    <div v-if="flag.completed">
      <ul :class="$style.cardlist" v-if="displayCards.length">
        <li
          :class="$style.credit_card"
          v-for="(card, i) in displayCards"
            v-bind:key="i">
            <div
              :class="[
                card.id === selectedCard ? $style.selected : '',
                $style.label
              ]"
              @click="selectedCard = card.id">
              <!-- カード -->
              <div
                v-if="card.id !== 'new'"
                :class="$style.card_img"
                v-bind:style="{ 'background-image':`url('/img/cards/${getCardIconName(card.brand)}.png')`}"></div>
              <p
                v-if="card.id !== 'new'">**** **** **** {{ card.last4 }}<span v-if="card.id === defaultCardId">（デフォルト）</span>
                <span :class="[$style.certLabel, card.type === 'paymentMethod' ? $style.certified : $style.uncertified]">
                  <i class="fa-regular me-1" :class="card.type === 'paymentMethod' ? 'fa-circle-check' : 'fa-circle-exclamation'"></i>
                  {{ card.type === 'paymentMethod' ? '認証済み' : '未認証' }}
                </span>
              </p>
              <!-- 新規登録label -->
              <div v-if="card.id === 'new'">
                <p>新規登録</p>
                <div v-if="selectedCard === 'new'"
                :class="$style.regist_wrap">
                  <CardRegist
                    :plan="plan"
                    v-on:registed="registed"
                    v-on:updatedDefaultCard="updateEditFlag"
                    ref="cardRegist" />
                </div>
              </div>
            </div>
        </li>
      </ul>
      <!-- <Spacer v-if="selectedCard === 'new'" :y="2"/> -->
      <Spacer :y="2"/>
      <ul :class="$style.btnlist" v-if="!hideBtn">
        <li v-if="selectedCard !== 'new'">
          <button
            :class="[
              $style.btn,
              $style.primary,
              selectedCard === user.subscribe.cards.default || selectedCard === 'new' || flag.progress || adjustCardType(selectedCard) === 'card' ? $style.disabled : ''
            ]"
            :disabled="selectedCard === user.subscribe.cards.default || selectedCard === 'new' || flag.progress || adjustCardType(selectedCard) === 'card'"
            @click="updateDefaultCard">このカードをデフォルトに設定</button>
        </li>
        <li v-if="selectedCard !== 'new'">
          <button
            :class="[
              $style.btn,
              $style.primary,
              selectedCard === user.subscribe.cards.default || selectedCard === 'new' || flag.progress ? $style.disabled : ''
            ]"
            @click="deleteCard">このカードを削除</button>
        </li>
        <li>
          <button
            :class="[$style.btn, $style.secondary]"
            :disabled="flag.progress"
            @click="updateEditFlag">キャンセル</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
import CardRegist from '@/components/parts/CardRegist.vue';
import Spacer from '@/components/Spacer';

export default {
  name: 'CardSelect',
  mixins: [cf],
  components: {
    CardRegist,
    Spacer,
  },
  props: {
    hideBtn: {
      type: Boolean,
      default: false,
    },
    plan: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      displayCards: [],
      flag: {
        progress: false,
        completed: true,
      },
      selectedCard: null,
    };
  },
  computed: {
    ...mapState(['user']),
    defaultCardId() {
      return this.user.subscribe.cards.default || this.user.subscribe.cards.oldDefault;
    },
  },
  created() {
    this.adjustDisplayCard();
  },
  watch: {
    user: {
      handler() {
        this.adjustDisplayCard();
      },
      deep: true,
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    adjustCardType(cardId) {
      const card = this.displayCards.find((c) => c.id === cardId);
      return card?.type;
    },

    adjustDisplayCard() {
      // completedフラグはDOM再生成用
      this.flag.completed = false;
      this.displayCards = this.user.subscribe?.cards?.cards || [];
      if (this.displayCards[this.displayCards.length - 1]?.id !== 'new') this.displayCards = [...this.displayCards, { id: 'new' }];
      this.selectedCard = this.user.subscribe.cards.default;
      this.flag.completed = true;
    },

    updateEditFlag() {
      this.$emit('updateEditFlag');
    },

    registed() {
      const unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/subscribe/putAllData') {
          unsubscribe();
          this.adjustDisplayCard();
        }
      });
    },

    updateDefaultCard() {
      this.flag.progress = true;
      this.showLoading();
      const cardType = this.adjustCardType(this.selectedCard);
      const data = {
        type: cardType,
        customerId: this.user.customer.customer_id,
        cardId: this.selectedCard,
      };
      this.axios({
        method: 'POST',
        url: '/v1/stripe/update/defaultCard',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/subscribe/getCustomer', this.user, { root: true });
          alert('お支払いカードを更新しました。');
          this.$emit('updatedDefaultCard');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            alert('お支払いカードの設定に失敗しました。再度お試しください。');
          } else {
            console.log(error);
          }
          this.hideLoading();
        })
        .finally(() => {
          this.hideLoading();
          this.updateEditFlag();
          this.flag.progress = false;
        });
    },
    deleteCard() {
      this.flag.progress = true;
      this.showLoading();
      const cardType = this.adjustCardType(this.selectedCard);
      const data = {
        type: cardType,
        customerId: this.user.customer.customer_id,
        cardId: this.selectedCard,
      };
      this.axios({
        method: 'POST',
        url: '/v1/stripe/set/deleteCreditcard',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/subscribe/getCustomer', this.user, { root: true });
          alert('カードを削除しました。');
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            alert('カードの削除に失敗しました。再度お試しください。');
          } else {
            console.log(error);
          }
          this.hideLoading();
        })
        .finally(() => {
          this.hideLoading();
          this.updateEditFlag();
          this.flag.progress = false;
        });
    },
  },
};
</script>

<style lang="scss" module>
.credit_card {
  display: flex;
}

.card_img {
  display: inline-block;
  height: 20px;
  width: calc(20px * 220 / 142);
  background-size: cover;
  border-radius: 2px;
  margin: auto 5px auto 0;
}

.card_No {
  font-size: 18px;
}

.edit_btn {
  font-size: 18px;
  cursor: pointer;
  text-decoration: underline;
}

.cardlist {
  li {
    margin-bottom: 10px;
  }
}

.label {
  border: 1px solid var(--lightgray);
  padding: 10px 16px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  cursor: pointer;
  &.selected {
    border-color: var(--black);
    border-width: 1.5px;
  }
}

.regist_wrap {
  display: flex;
}

.btnlist {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 128px;
  height: 40px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  transition: all .3s;

  &.primary {
    background-color: rgba(26, 34, 61, 1);
    border: none;
    color: #fff;
    &:hover, &.disabled {
      opacity: .6;
      cursor: default;
    }
  }

  &.secondary {
    border: 1px solid rgba(26, 34, 61, 1);
    color: var(--font-black);
    background-color: #fff;
    &:hover {
      opacity: .4;
    }
  }
}
.certLabel {
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 4px;
  &.certified {
    border: 1px solid #2a8536;
    background-color: #fff;
    color: #339c41;
  }
  &.uncertified {
    border: 1px solid #8a8d91;
    background-color: #fff;
    color: #8a8d91;
  }
}
</style>
